import { combineReducers } from "redux";
import admin from "./store/admin/reducer";
import accessToken from "./store/accessToken/reducer";
import refreshToken from "./store/refreshToken/reducer";
import login from "containers/Login/redux/store/reducer";
import verify from "containers/Verify/redux/store/reducer";
import breadcrumbs from "./store/breadcrumbs/reducer";
import network from "./store/network/reducer";
import balance from "./store/balance/reducer";
import socket from "./store/socket/reducer";
import messageLanguage from "./store/messageLanguage/reducer";

const rootReducer = () =>
  combineReducers({
    admin: admin,
    accessToken: accessToken,
    refreshToken: refreshToken,
    login: login,
    verify: verify,
    breadcrumbs: breadcrumbs,
    network: network,
    balance: balance,
    socket,
    messageLanguage
  });

export default rootReducer;
