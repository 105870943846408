import FieldText from "components/FieldText";
import styles from "./index.module.scss";
import { useQueryCustom, useQueryCustomWithoutURL } from "utils/useQueryCustom";
import axiosClient from 'lib/axiosConfig';
import api from "operations/network/api";
import EmojiPicker from "emoji-picker-react";
import Loading from "components/Loading";
import SelectFileComponent from "components/SelectFileComponent";
import { useMemo, useRef, useState } from "react";
import ModalLayout from "components/ModalLayout";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fieldNames, validation } from "./validation";
import FieldSelect from "components/FieldSelect";
import { useEffect } from "react";
import { useMutationCustom } from "utils/useMutationCustom";
import { Button, Form } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import messageLanguageAction from "redux/store/messageLanguage/action";
import { useParams } from "react-router-dom";
import useClickTarget from "hooks/useClickTarget";

// key =

const text = {
    fromLang: {
        placeholder: "Search from language"
    },
    toLang: {
        placeholder: "Search to language"
    },
}

const SelectLanguage = ({ languages, name, value, defaultValue }) => {

    let { id } = useParams();

    // const findDefaultValue = languages?.find((x) => x.value == value)

    const updateAdminSetting = async (params) => await axiosClient().put(`${api.chat.base}/admin-setting/${id}`, params);
    const onSuccessMutating = (data) => {
        console.log("* * * onSuccessMutating :", { data });
    };
    const { isLoading, data, mutate } = useMutationCustom({
        url: updateAdminSetting,
        onSuccess: onSuccessMutating,
        // name: `get_chat_admin_setting_${id}`,
        invalidQuery: `get_chat_admin_setting_${id}`
    });



    const [searchText, setSearchText] = useState()
    const [toggleLanguage, setToggleLanguage] = useState()

    const dispatch = useDispatch()
    const refContent = useRef()

    // const languageSelector = useSelector(state => state.messageLanguage);
    // const currentLanguage = languageSelector[id];

    const handleShowLanguage = () => {
        setToggleLanguage(p => !p)
    }
    const handleSelectLanguage = (language) => {
        console.log({ language });
        // dispatch(messageLanguageAction.set(id, language))
        handleShowLanguage()
        if (defaultValue && (value == defaultValue && !language?.value)) return;
        mutate({ [name]: language?.value || defaultValue || false })


    }



    const handleToggle = () => {
        // console.log("eeeeeeeeeeeeee");
        handleShowLanguage()
    }
    useClickTarget(refContent, toggleLanguage, handleToggle)


    console.log("* * * SelectLanguage languages :", { name, languages, value });

    return (
        <div ref={refContent}>

            <div className={styles.translateBadge} onClick={handleShowLanguage}>
                {value?.toLowerCase?.() || "Language"}
                <i className="mdi mdi-chevron-down" />
            </div>


            <div className={[styles.selectLanguage, toggleLanguage && styles["visible"]].join(" ")}>
                <div className={[styles['container'], "p-3"].join(" ")}>


                    <div className={styles.head}>
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text bg-white"><i className="mdi mdi-magnify" /></div>
                            </div>
                            <Form.Control
                                onChange={(e) => setSearchText(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder={text[name]?.placeholder}
                            />
                        </div>
                    </div>

                    <div className={[styles.body, " pt-4 pb-0 relative"].join(" ")}>
                        <div className={styles.bodyWrap}>
                            {
                                languages?.map((x) => {
                                    let active = value == x.value;

                                    // console.log(!x?.label?.toLowerCase()?.includes(searchText), x?.label?.toLowerCase(), searchText);
                                    if (searchText && !x?.label?.toLowerCase()?.includes(searchText)) {
                                        return;
                                    }
                                    return <div className={styles.item} onClick={() => handleSelectLanguage(active ? "" : x)}>

                                        <div className={styles.itemBox}>
                                            <div className={[styles.active, active ? styles.show : ""].join(" ")}>
                                                <i className="mdi mdi-check" />
                                            </div>
                                            {x?.label}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                {/* <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
    {!text ?
        <FieldText
            require
            register={register}
            name={fieldNames.text}
            placeholder={"text"}
            label={"Text"}
            errors={errors}
        />
        : ""}

    <FieldSelect
        require
        {...{
            name: fieldNames.language,
            register,
            label: "Language",
            errors,
            control,
            options: languages,
        }}
    />

    <FieldText
        disabled={!translateText}
        require
        register={register}
        name={fieldNames.translatedText}
        placeholder={"Translated"}
        label={"Translated"}
        errors={errors}
    />

    <div className="d-flex">

        <Button block type="button" className={`btn btn-warning btn-sm`} disabled={isLoading} onClick={handleCancel} >
            {"Cancel"}
        </Button>
        <div className="mr-2"></div>
        <Button block type="submit" className={`btn btn-success btn-sm`} disabled={isLoading}>
            {!isLoading ? "Translate" : "Loading..."}
        </Button>

        <div className="mr-auto"></div>
        <Button block type="button" className={`btn btn-info btn-sm`} onClick={() => handleAccepted(translateText)} disabled={!translateText}>
            {"done"}
        </Button>
    </div>

</form> */}

            </div>
        </div>

    )
}
export default SelectLanguage