import React, { useState, useEffect } from "react";
const useOnScreen = (reference, rootMargin = "0px", withClass) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        return !withClass
          ? !isIntersecting &&
          entry.isIntersecting &&
          setIntersecting(entry.isIntersecting)
          : entry.isIntersecting && reference?.current?.classList?.add?.("observed");
      },
      { rootMargin }
    );

    if (reference?.current) {
      observer?.observe(reference?.current);
    }
    return () => {
      if (reference?.current) observer?.unobserve(reference?.current);
    };
  }, [reference]);

  return isIntersecting;
};

export default useOnScreen;
