
import actionTypes from "./actionTypes";
import initialState from "./initialState";

export const set = (state, { chat, language }) => ({
  ...state,
  [chat]: { ...language, createdAt: new Date().getTime() },

});


const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case actionTypes.SET:
      return set(state, action);
    default:
      return state
  }
}

export default reducer;