import { format, formatDistanceToNowStrict, formatISO, isYesterday } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import routes from "operations/routing/routes";
import { useDispatch, useSelector } from "react-redux";
import getDataInObjectDepth from "utils/getDataInObject";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import axiosClient from "lib/axiosConfig";

import SingleChat from "../chat";
import { useQueryCustom } from "utils/useQueryCustom";
import api from "operations/network/api";
import { API_NAME } from "../enum";
// import { panelSocket } from "operations/network/socket";
import Loading from "components/Loading";
import { PLACEHOLDER_IMAGE } from "enumerations";
import FieldText from "components/FieldText";
import moment from 'moment';
import SidebarChatList from "./SidebarChatList";
import { useParams } from "react-router-dom";


const List = () => {
    let { id } = useParams();
    const eventType = {
        onlineStatus: "onlineStatus",
        chatList: "chatList",
        newMessage: "newMessage",
    }
    const socketState = useSelector((state) => state.socket);
    const panelSocket = socketState.data
    // console.log({ panelSocket });

    const adminData = useSelector(store => store.admin)

    // const [socket, setSocket] = useState()

    const [OnlineUsers, setOnlineUsers] = useState([]);
    const [NewMessages, setNewMessages] = useState({});
    const [ChatsList, setChatsList] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 15,
        page: 1,
        sortBy: 'updatedAt:desc',
    });

    const newRoute = useMemo(() => getDataInObjectDepth("chat", routes), []);

    const dispatch = useDispatch();


    const [SelectedChatId, setSelectedChatId] = useState(-1)
    const [SelectedChatData, setSelectedChatData] = useState({});
    const [searchList, setSearchList] = useState()
    const [searchLoading, setSearchLoading] = useState()

    useEffect(() => {
        if (id) setSelectedChatId(id)
    }, [])
    // breadcrumbs
    const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard }, { title: "Chat", link: newRoute.base }, { title: "List" }];

    const getting = async () =>
        await axiosClient().get(api[API_NAME].list, {
            params: {
                // sortBy: 'updatedAt:desc',
                // limit: 150,
                // ...(search && { search }),

                ...pagination
            },
        });

    const gettingLanguages = async (params) => await axiosClient().get(`${api["translate"].base}/languages`, params);
    const languagesRequest = useQueryCustom({
        name: `get_languages`,
        url: gettingLanguages,
    });
    // console.log({ OnlineUsers });
    // console.log({ NewMessages });
    // console.log({ ChatsList, searchList });

    const languages = useMemo(() => {
        console.log({ languagesRequest });
        const data = languagesRequest?.data?.data?.data;
        if (!data) return []
        return {
            from: data?.from?.map((x) => ({ label: `${x?.name}`, value: x?.code })),
            to: data?.to?.map((x) => ({ label: `${x?.name}`, value: x?.code })),
        }
    }, [languagesRequest])
    console.log({ languages });

    const setSearchState = async (search) => {
        console.log({ search });
        setSearchLoading(true)
        if (search) {
            const searchData = await axiosClient().get(api[API_NAME].list, {
                params: {
                    ...(search && { search }),
                    limit: pagination.limit
                },
            });
            setSearchLoading(false)

            setSearchList(searchData?.data?.data?.results || [])
            console.log({ searchData });
        } else {
            setSearchList()
            // setChatsList([])
            setSearchLoading(false)
        }

    }


    console.log({ SelectedChatData });

    const handleSuccessGetData = ({ data }) => {
        // if (searchList) return
        // alert("aaa")

        console.log("* * * handleSuccessGetData:", { data });
        let params = [...ChatsList];
        params = [...params, ...(data?.data?.results || [])];
        // let params = { ...data?.data };
        setChatsList(params);

        // let onlineUsersState = []
        //     // [...(OnlineUsers || [])]
        //     ,
        //     chatListState = params
        //     // [                ...(params?.results || [])]
        //     ,
        //     newMessagesState = {}
        // // { ...(NewMessages || {})         }

        // const callback = ({ data: newData, type }) => {

        //     console.log("* * * callback", { newData, type });

        //     switch (type) {
        //         case eventType.onlineStatus:
        //             onlineUsersState = newData;
        //             setOnlineUsers(p => ([...p, ...newData]))
        //             break;
        //         case eventType.chatList:
        //             chatListState = newData;
        //             setChatsList(p => ([...p, ...newData]))
        //             break;
        //         case eventType.newMessage:
        //             newMessagesState = newData;
        //             setNewMessages(p => ({ ...p, ...newData }))
        //             break;
        //         default:
        //             break;
        //     }
        // }

        // handleListenSocket({
        //     state: {
        //         onlineUsersState, chatListState, newMessagesState
        //     },
        //     callback
        // })
    }

    const {
        data: { data = {} } = {},
        error,
        isError,
        isLoading,
        isFetching,
        refetch,
    } = useQueryCustom({
        name: `${API_NAME}_get`,
        params: {
            limit: pagination.limit,
            page: pagination.page,
            sortBy: pagination.sortBy,
        },
        url: getting,
        onSuccess: handleSuccessGetData,
        // enabled: !searchList
    });
    // const refetch = () => { }
    const total = data?.data?.totalResults;
    const maxPage = data?.data?.totalPages;
    const havNextPage = searchList ? false : pagination?.page < maxPage
    console.log({ page: pagination?.page, total, maxPage, searchLoading, havNextPage });
    // console.log({ data, isFetching, isLoading, isFetched, isRefetching });

    const handleNextPage = (props) => {
        console.log("* * * handleNextPage:", { props });

        const nextPage = pagination.page + 1;
        if (havNextPage)
            setPagination((p) => ({ ...p, page: nextPage }));
    };

    const handleFetchNewChat = async (chatId) => {
        try {
            const findChat = await axiosClient().get(api[API_NAME].list + `/by-id/${chatId}/messages`);
            console.log({ findChat });
            let resData = findChat.data.data
            console.log({ resData });
            const findSystemChat = resData.filter(x => x.user.type == "system")[0]
            console.log({ findSystemChat });
            if (findSystemChat)
                setChatsList((state) => {
                    let newState = [...state]
                    console.log({ newState });
                    if (+(pagination.limit * pagination.page) == +state?.length) newState.splice(state?.length - 1, 1);
                    newState.unshift(findSystemChat);
                    console.log("* * * handleFetchNewChat: ", { newState });

                    return newState;
                })
        } catch (error) {
            console.log("* * * handleFetchNewChat", { error });
        }

    }

    const incrementChatMessageCount = (params) => {
        console.log("* * * * incrementChatMessageCount : ", { params })
        const data = params.result
        // const isSystemUser = data.user.type === "system"

        setChatsList((state) => {
            console.log({ state });
            let newState = [...state]
            let currentIndex = newState.findIndex((x) => x._id == data.chat)
            console.log({ currentIndex });
            if (!(currentIndex >= 0)) {
                if (data.chat) handleFetchNewChat(data.chat)
                return state;
            }


            if (data.content) {
                if (newState[currentIndex]?.lastMessage) {

                    newState[currentIndex].lastMessage.createdAt = data.createdAt;
                    newState[currentIndex].lastMessage.messageId = data.messageId;
                    newState[currentIndex].lastMessage.message = data
                    // newState[currentIndex].lastMessage.message.content.caption = data.content.caption;
                    // if (newState?.[currentIndex]?.lastMessage?.message?.header?.type) newState[currentIndex].lastMessage.message.header.type = data.header.type;
                }
            }
            // if (data.message) {
            //     const d = new Date();
            //     newState[currentIndex].lastMessage.createdAt = d.toISOString();
            //     newState[currentIndex].lastMessage.message.content.caption = data.message.caption;
            //     if (newState?.[currentIndex]?.lastMessage?.message?.header?.type) newState[currentIndex].lastMessage.message.header.type = data.message.type;
            // }
            if (currentIndex > 0) {
                const elementToMove = newState.splice(currentIndex, 1)[0];
                newState.unshift(elementToMove);
            }

            setSelectedChatId((prev) => {
                console.log({ prev });
                if (data.chat == prev) return prev
                newState[0].unreadMessageCount = params.unreadMessageCount// (newState[0].unreadMessageCount || 0) + 1
                return prev
            })
            console.log({ newState, currentIndex, data });
            return newState;
        });

    }
    const decrementChatMessageCount = (data) => {
        console.log("* * * * decrementChatMessageCount : ", { data })

        setChatsList((state) => {
            console.log({ state });
            let newState = [...state]
            let currentIndex = newState.findIndex((x) => x._id == data.chat)
            if (!newState[currentIndex]) return state
            newState[currentIndex].unreadMessageCount = ((newState[currentIndex].unreadMessageCount || 0) - 1) || 0
            console.log({ newState, currentIndex, data });
            return newState;
        });
    }

    const zeroChatMessageCount = (chatId) => {
        console.log("* * * * zeroChatMessageCount : ", { chatId })
        setChatsList((state) => {
            console.log({ state });
            let newState = [...state]
            let currentIndex = newState.findIndex((x) => x._id == chatId)
            if (!newState[currentIndex]) return state
            newState[currentIndex].unreadMessageCount = 0
            console.log({ newState, currentIndex, chatId });
            return newState;
        });
    }


    const handleSelectedChat = (chat) => {

        // let helpObj = { ...NewMessages };

        // if (helpObj[chat?._id]) {
        //     helpObj[chat?._id]["count"] = 0
        // }

        // setNewMessages(helpObj);
        zeroChatMessageCount(chat._id)
        handleReadMessage(chat)
        setSelectedChatId(chat._id);
        setSelectedChatData(chat);
    }

    const handleReadMessage = (chat) => {
        panelSocket.emit('unread message update', {
            "chatId": chat?._id,
            "messageId": chat?.lastMessage?.messageId
        }, (data) => {
            console.log({ data }, "unread message update")
        });
    }

    const handleSortChatList = () => {
        let helpArr = [...ChatsList];
        helpArr.sort((a, b) => a?.lastMessage?.createdAt < b?.lastMessage?.createdAt)
        setChatsList(helpArr)
        // console.log("New Chat List")
        // console.log(helpArr)
    }
    // ------------------------------------------------------------------------------------------------------------------  socket 



    // --------------------------------------------------------- 

    const onlineStatus = (data, { state, callback } = {}) => {
        console.log("* * * onlineStatus : ", { data })
        let helpArr = [...state.onlineUsersState];
        if (data.status === "online") {
            helpArr.push(data?.user);
        } else {
            helpArr.filter(item => item !== data?.user)
        }

        // setOnlineUsers(helpArr)
        callback({ type: eventType.onlineStatus, data: helpArr })
        console.log({ OnlineUsers, data })
    }
    // --------------------------------------------------------- 

    const chatMessage = (data /*, { state, callback } = {} */) => {
        console.log("* * * chatMessage : ", { data })
        incrementChatMessageCount(data)
    }
    // --------------------------------------------------------- 
    const newChat = (params) => {
        console.log("* * * new chat: ", { params })
        const data = params?.result || params

        setChatsList((state) => {
            console.log({ state });
            let newState = [...state]
            let currentIndex = newState.findIndex((x) => x._id == data._id)
            console.log({ currentIndex });
            if (!(currentIndex >= 0)) {
                data.unreadMessageCount = data.unreadMessageCount || 1
                let newState = [...state]
                console.log({ newState });
                newState.splice(state?.length - 1, 1);
                newState.unshift(data);
                return newState;
            }

            if (data.lastMessage) {
                newState[currentIndex].lastMessage = data.lastMessage
            }

            if (currentIndex > 0) {
                const elementToMove = newState.splice(currentIndex, 1)[0];
                newState.unshift(elementToMove);
            }

            setSelectedChatId((prev) => {
                console.log({ prev });
                if (data._id == prev) return prev
                newState[0].unreadMessageCount = params.unreadMessageCount || (newState[0].unreadMessageCount || 0) + 1
                console.log(newState[0].unreadMessageCount, "newState[0].unreadMessageCount");
                return prev
            })
            console.log({ newState, currentIndex, data });
            return newState;
        });

    }
    // --------------------------------------------------------- 
    const newMyChatMessage = (params) => {
        console.log("* * * * newMyChatMessage : ", { params })

        const data = params.result
        // const isSystemUser = data.user.type === "system"

        setChatsList((state) => {
            console.log({ state });
            let newState = [...state]
            let currentIndex = newState.findIndex((x) => x._id == data.chat)
            console.log("* * * newMyChatMessage: ", { currentIndex });
            console.log("* * * newMyChatMessage: ", { newState });
            // if (!(currentIndex >= 0)) {
            //     if (data.chat) handleFetchNewChat(data.chat)
            //     return state;
            // }


            if (data.content) {
                if (newState[currentIndex]?.lastMessage) {

                    newState[currentIndex].lastMessage.createdAt = data.createdAt;
                    newState[currentIndex].lastMessage.messageId = data.messageId;
                    newState[currentIndex].lastMessage.message = data
                    // newState[currentIndex].lastMessage.message.content.caption = data.content.caption;
                    // if (newState?.[currentIndex]?.lastMessage?.message?.header?.type) newState[currentIndex].lastMessage.message.header.type = data.header.type;
                }
            }

            // if (data.message) {
            //     const d = new Date();
            //     newState[currentIndex].lastMessage.createdAt = d.toISOString();
            //     newState[currentIndex].lastMessage.message.content.caption = data.message.caption;
            //     if (newState?.[currentIndex]?.lastMessage?.message?.header?.type) newState[currentIndex].lastMessage.message.header.type = data.message.type;
            // }
            if (currentIndex > 0) {
                const elementToMove = newState.splice(currentIndex, 1)[0];
                newState.unshift(elementToMove);
            }

            setSelectedChatId((prev) => {
                console.log({ prev });
                if (data.chat == prev) return prev
                newState[0].unreadMessageCount = params.unreadMessageCount
                return prev
            })
            console.log({ newState, currentIndex, data });
            return newState;
        });

    }

    const handleListenSocket = (params) => {
        console.log("* * * panelSocket:", { panelSocket })
        // // --------------------------------- off before event
        // panelSocket.off('onlineStatus', onlineStatus);
        // panelSocket.off('chat message', chatMessage);
        // panelSocket.off('new chat', newChat);
        // --------------------------------- on new event
        // panelSocket.on('onlineStatus', (e) => onlineStatus(e, params));
        // panelSocket.on('chat message', (e) => chatMessage(e, params));
        // panelSocket.on('new chat', (e) => newChat(e, params));
    }

    useEffect(() => {
        dispatch(actionBreadcrumbs.set(breadcrumbs));
        document.body.classList.add("sidebar-icon-only")
        // handleListenSocket();
        // handleSortChatList();
        // panelSocket.connect()
        if (!socketState.data) return;
        panelSocket.on('onlineStatus', onlineStatus);
        panelSocket.on('chat message', chatMessage);
        panelSocket.on('new chat', newChat);
        panelSocket.on('/agent/my-chat-message', newMyChatMessage);

        return () => {
            panelSocket.off('onlineStatus', onlineStatus);
            panelSocket.off('chat message', chatMessage);
            panelSocket.off('new chat', newChat);
            panelSocket.off('/agent/my-chat-message', newMyChatMessage);
        }
    }, [socketState])




    return (
        <>
            <div className="d-flex align-items-stretch chatMainBox">

                <SidebarChatList  {...{
                    isLoading, isFetching, searchLoading, setSearchState,
                    ChatsList: searchList || ChatsList, NewMessages, SelectedChatId,
                    handleSelectedChat, handleNextPage, havNextPage
                }} />

                <div className="col-9 p-0 showChatDetailsMainBox">
                    {SelectedChatId === -1 ?
                        <>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="selectOneChatMessageBox">
                                    <i className="mdi mdi-message-text menu-icon"></i>
                                    <div className="">Select Chat to start conversion</div>
                                </div>
                            </div>
                        </>
                        :
                        <SingleChat
                            key={SelectedChatId}
                            ChatId={SelectedChatId}
                            SelectedChat={SelectedChatData}
                            refechChatList={refetch}
                            chatListItems={ChatsList}
                            setChatListItems={setChatsList}
                            handleSortChatList={handleSortChatList}
                            panelSocket={panelSocket}
                            isLoading={isLoading}
                            languages={languages}
                        />

                    }
                </div>
            </div>


        </>
    );
}

export default List;