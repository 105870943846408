import { PLACEHOLDER_IMAGE } from "enumerations";
import moment from "moment";
import api from "operations/network/api";
import { useMutationCustom } from "utils/useMutationCustom";
import axiosClient from 'lib/axiosConfig';
import { useState } from "react";


const MessageComponent = ({ currentUser, message, ChatId, handleShowUserAvatarPreview }) => {
    const [translate, setTranslate] = useState("")
    let language = "tr"
    const update = async (params) => await axiosClient().put(api["chat"].base + `/message/translate`, params);

    const editMessage = useMutationCustom({
        url: update,
        name: `get_message_translate_${ChatId}`,
    });


    const newMessage = editMessage?.data?.data?.data || message;
    console.log({ editMessage, newMessage });

    const handleTranslate = () => {
        if (!newMessage?.translate?.[language]) editMessage.mutate({ message: message?._id, language: language })
        setTranslate(language)
    }
    const handleOriginal = () => {

        setTranslate()
    }
    let translated = translate && newMessage?.translate?.[language];
    let caption = translated || newMessage?.content?.caption
    let isSystem = currentUser?.type === "system";

    return (
        <div className="d-flex align-items-end messageCardBox" key={`chat-message-${ChatId}-${newMessage?._id}`}>
            {!(currentUser?.type === "system") &&

                <img src={currentUser?.avatar?.url || PLACEHOLDER_IMAGE} alt="" />

            }

            <div className={`messageCard ${(currentUser?.type === "system") ? "me" : ""}`}>
                <div className="messageCardName">{newMessage?.me ? "You" : currentUser?.name}</div>
                <div className="messageCardBody">
                    {newMessage?.header?.type === "text" ?
                        <>
                            {caption}
                        </>
                        :
                        <div className="chat-image">
                            <img className="rounded cursor-pointer" src={newMessage?.content?.image?.secureUrl} onClick={() => handleShowUserAvatarPreview(newMessage?.content?.image?.secureUrl)} />
                            <div className="my-1">
                                {caption}
                            </div>
                        </div>
                    }
                </div>
                <div className="messageCardFooter d-flex align-items-center">
                    <div className="messageCardFooterDate mr-2">
                        {/* {format(new Date(newMessage?.createdAt), "HH:MM")} */}
                        {moment(newMessage?.createdAt).format('MM.DD , HH:mm')}
                    </div>
                    {
                        !isSystem &&
                            newMessage?.content?.caption?.length ?
                            translated ?
                                <small onClick={handleOriginal} className="cursor-pointer">
                                    {
                                        "See Original"
                                    }
                                </small>

                                : <small onClick={!editMessage?.isLoading && handleTranslate} className="cursor-pointer" aria-disabled={true}>
                                    {
                                        editMessage?.isLoading ?
                                            "translating ..."
                                            :
                                            "see translate"
                                    }
                                </small>
                            : ""}

                </div>
            </div>
        </div>)
}

export default MessageComponent;