function isoToDate(createdAt) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let dt = new Date(createdAt);
  return (
    dt.getDate() +
    " " +
    monthNames[dt.getMonth()] +
    " " +
    dt.getFullYear() +
    " - " +
    dt.getHours() +
    " : " +
    (dt.getMinutes() < 10 ? "0" : "") +
    dt.getMinutes()
  );
}
export default isoToDate;
