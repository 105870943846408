import Loading from "components/Loading";
import { PLACEHOLDER_IMAGE } from "enumerations";
import FieldText from "components/FieldText";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Fragment, memo, useRef } from "react";
import { formatDistanceToNowStrict, isYesterday } from "date-fns";
import { format } from "date-fns/esm";
import FindMe from "components/FindMe";
import Spinner from "components/Spinner";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import routes from "operations/routing/routes";

const SidebarChatList = ({ isLoading, isFetching, searchLoading, setSearchState, ChatsList, havNextPage, SelectedChatId, handleSelectedChat, handleNextPage }) => {
    const adminData = useSelector(store => store.admin)
    const navigation = useNavigate();

    const inputInterval = useRef()

    const handleSearch = (e) => {
        const value = e.target.value;
        if (value?.length < 3 && value?.length > 0) return;
        clearTimeout(inputInterval.current)
        inputInterval.current = setTimeout(() => {
            setSearchState(value)
        }, 1000);

    }

    return (
        <div className="col-3 p-2 chatSideBarMainBox">
            {/* <div className="p-2 chatSidebarHeaderMainBox">
                        <div className="h3">Chats</div>
                    </div> */}

            <FieldText
                className={"chatSearchInput"}
                parentClass={"mb-0"}
                type={"text"}
                placeholder={"Search"}
                onChange={handleSearch}
            />
            {
                // searchLoading ?
                //     <>
                //         <div className="d-flex justify-content-center mt-1">
                //             <Loading />
                //         </div>
                //     </>
                //     :
                ((isLoading || isFetching) && !ChatsList?.length || searchLoading) ?
                    <>
                        <div className={`d-flex justify-content-center mt-1 ${styles["wrapper"]}`}>
                            {/* <Loading /> */}
                            {
                                Array.apply(null, new Array(20))?.map((x) => {
                                    return (
                                        <div className={`${styles["skeleton-loader"]} mb-1`}></div>
                                    )
                                })
                            }

                            {/* <div className={` ${styles["details"]}`}>
                                        <span className={`${styles["prod-name"]} ${styles["skeleton-loader"]}`}></span>
                                        <span className={` ${styles["prod-description"]} ${styles["skeleton-loader"]}`}></span>
                                    </div> */}
                        </div>
                    </>
                    : ChatsList?.length ?
                        <>
                            <div className="chatSidebarMainBodyBox">

                                {ChatsList?.map((chat, i) =>
                                    <div className="my-1 chat-sidebar-item" key={"sidebar-chat-system-" + i}>
                                        {/* {console.log({ "chat?.lastMessage?.createdAt": chat?.lastMessage?.createdAt })}
                                            {console.log({ "chat?.lastMessage": chat?.lastMessage })} */}
                                        {/* {console.log({ "chat": chat })} */}
                                        <div className={
                                            `d-flex align-items-center gap-2 p-2 chatCard 
                                                ${SelectedChatId === chat._id ? " selected" : ""}
                                                ${chat.unreadMessageCount && "newMessage"}`
                                        } onClick={() => {
                                            if (SelectedChatId === chat._id) return;
                                            navigation(`${routes.chat.base + routes.chat.list}/${chat._id}`)
                                            handleSelectedChat(chat)
                                        }}>
                                            <div className={"chatCardAvatar"}>
                                                {chat?.members?.map(item =>
                                                (
                                                    item.type != 'system' &&
                                                    <>
                                                        {
                                                            item.avatar?.url ?
                                                                <img src={item.avatar?.url} alt="" />
                                                                :
                                                                <div className="avatar-name-holder">
                                                                    <span>{item?.name?.slice(0, 2)}</span>
                                                                </div>
                                                        }
                                                    </>

                                                )
                                                )}
                                            </div>
                                            <div className="chatCardDetail">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="chatCardName">
                                                        {chat?.members?.map((item, index) =>
                                                        (
                                                            item.type != 'system' &&
                                                            <b>
                                                                {item.status == "delete" ? "deleted" : item.name}
                                                            </b>
                                                        )
                                                        )}
                                                        <b> / </b>
                                                        {chat?.members?.map((item, index) =>
                                                        (
                                                            item.type == 'system' &&
                                                            <span className="text-muted">
                                                                {item.status == "delete" ? "deleted" : item.name}
                                                            </span>
                                                        )
                                                        )}

                                                    </div>
                                                    {chat?.lastMessage ?

                                                        <div className="chatCardLastTime text-muted">{(() => {
                                                            if (
                                                                formatDistanceToNowStrict(new Date(chat?.lastMessage?.createdAt))?.search("hour") > -1 ||
                                                                formatDistanceToNowStrict(new Date(chat?.lastMessage?.createdAt))?.search("minute") > -1 ||
                                                                formatDistanceToNowStrict(new Date(chat?.lastMessage?.createdAt))?.search("second") > -1
                                                            ) {
                                                                if (isYesterday(new Date(chat?.lastMessage?.createdAt))) {
                                                                    return format(new Date(chat?.lastMessage?.createdAt), "EE")

                                                                } else {
                                                                    return moment(new Date(chat?.lastMessage?.createdAt)).format('HH:mm')
                                                                    //  format(new Date(chat?.lastMessage?.createdAt), "HH:MM")
                                                                }
                                                            } else if (parseInt(formatDistanceToNowStrict(new Date(chat?.lastMessage?.createdAt))?.split(" ")[0]) <= 7) {
                                                                return format(new Date(chat?.lastMessage?.createdAt), "EE")
                                                            } else {
                                                                return format(new Date(chat?.lastMessage?.createdAt), "yyyy/MM/dd")
                                                            }
                                                        })()}</div> : ""
                                                    }
                                                </div>


                                                <>

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="chatCardLastMessage text-muted text-truncate">
                                                            {chat.lastMessage?.message?.content?.caption || "Photo"}</div>
                                                        {chat.unreadMessageCount ?
                                                            <div className="chatCardUnReadCount ">{chat.unreadMessageCount}</div> : ""
                                                        }
                                                    </div>


                                                </>
                                                {/* {
                                                        chat?.unreadMessageCount  !== 0? 
                                                        
                                                        <>

                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="chatCardLastMessage text-muted text-truncate">{NewMessages[chat?._id].lastMessage}</div>
                                                                {NewMessages[chat?._id].count !== 0 &&
                                                                    <div className="chatCardUnReadCount ">{NewMessages[chat?._id].count}</div>
                                                                }
                                                            </div>


                                                        </>
                                                        :
                                                        <>
                                                        <div className="chatCardLastMessage text-muted text-truncate">{chat.lastMessage?.message?.content?.caption}</div>
                                                    </>
                                                    } */}
                                                {/* {NewMessages[chat._id] ?

                                                        <>

                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="chatCardLastMessage text-muted text-truncate">{NewMessages[chat?._id].lastMessage}</div>
                                                                {NewMessages[chat?._id].count !== 0 &&
                                                                    <div className="chatCardUnReadCount ">{NewMessages[chat?._id].count}</div>
                                                                }
                                                            </div>


                                                        </>

                                                        :
                                                        <>
                                                            <div className="chatCardLastMessage text-muted text-truncate">{chat.lastMessage?.message?.content?.caption}</div>
                                                        </>

                                                    } */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {isLoading ? (
                                    <div className="text-center">
                                        <Loading />
                                    </div>
                                ) : ChatsList?.length && havNextPage ? (
                                    <FindMe callback={handleNextPage} margin={"50px"} />
                                ) : null}
                            </div>


                        </>
                        :
                        ChatsList?.length === 0 &&
                        <>
                            <div className="h5 text-center text-content mt-2"> No Chats !</div>
                        </>


            }
        </div>
    )

}

export default SidebarChatList;