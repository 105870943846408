import FieldText from "components/FieldText";
import toastify from "lib/toastify";
// import { panelSocket } from "operations/network/socket";
import { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import axiosClient from "lib/axiosConfig";
import api from "operations/network/api";
import { API_NAME, DEFAULT_CHAT_SETTING } from "../enum";
import { ONLINE_STATUSES, ONLINE_STATUSES_OBJECT, PLACEHOLDER_IMAGE, STATUSES, STATUSES_OBJECT } from "enumerations";
import Loading from "components/Loading";
import EmojiPicker from "emoji-picker-react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Link, Router, useParams } from "react-router-dom";
import routes from "operations/routing/routes";
import { formatISO } from "date-fns";
import moment from 'moment';
import Note from "../Notes/Note";
import SelectFileComponent from "components/SelectFileComponent";
import { useSelector } from "react-redux";
import formatTimeAgo from "utils/formatTimeAgo";
import { useQueryCustom } from "utils/useQueryCustom";
import FindMe from "components/FindMe";
import { useInfiniteQuery } from "react-query";
import delay from "lib/delay";
import { Fragment } from "react";
import styles from "./index.module.scss";
import ChatFooter from "./Footer";
import DropDownBootstrap from "components/DropDownBootstrap";
import { useMutationCustom } from "utils/useMutationCustom";
import MessageComponent from "./MessageCompoenent";

const SingleChat = ({ ChatId, SelectedChat, refechChatList, chatListItems, setChatListItems, languages }) => {
    let chatDateRecent = '';
    console.log("chatListItems", chatListItems);
    let { id } = useParams();
    if (!SelectedChat?._id) SelectedChat = { _id: id }

    const chatMainBody = useRef();
    const topChatMainBody = useRef();
    const messageInputRef = useRef()
    const [MessageInputRow, setMessageInputRow] = useState(1);
    const [ChatMessageText, setChatMessageText] = useState("");
    const [ChatMessages, setChatMessages] = useState();
    const [FlatChatMessages, setFlatChatMessages] = useState();
    const [ShowObserve, setShowObserve] = useState();
    // const [isConnected, setIsConnected] = useState(panelSocket.connected);
    const [fooEvents, setFooEvents] = useState([]);
    const [ChatLoading, setChatLoading] = useState(true);
    const [ChatIsFetching, setChatIsFetching] = useState(true);
    const [SendTextLoading, setSendTextLoading] = useState(false);

    const [ShowSystemPartner, setShowSystemPartner] = useState(false);

    const [OpenChatInfo, setOpenChatInfo] = useState(false);
    const [PreviewUserAvatar, setPreviewUserAvatar] = useState({
        show: false,
        data: {}
    })
    const socketState = useSelector((state) => state.socket);
    const panelSocket = socketState.data




    const [IsDeleteAccount, setIsDeleteAccount] = useState();
    const [FakeUser, setFakeUser] = useState({});
    const [RealUser, setRealUser] = useState({});
    let realUserVar = {};

    const [pagination, setPagination] = useState({
        limit: 10,
        page: 1,
        sortBy: 'createdAt:desc',
        chat: ChatId,
        totalPages: 2,
        // userId: ChatId,
    });


    // const total = pagination?.totalResults;
    // const maxPage = Math.ceil(total / pagination?.limit);
    // console.log({ total, maxPage });
    const indexOfChat = chatListItems?.findIndex((x) => x._id == SelectedChat?._id);
    // const user = SelectedChat?.members?.find((x) => x.type == "system")
    const getting = async () => await axiosClient().get(api[API_NAME].list + `/by-id/${ChatId}`);
    const gettingChatAdminSetting = async () => await axiosClient().get(api[API_NAME].list + `/admin-setting/${ChatId}`);
    const updateUserStatus = async (params) => await axiosClient().put(api["user"].base + `/online-status/${FakeUser?._id}`, params);

    const chatAdminSettingRequest = useQueryCustom({
        name: `get_chat_admin_setting_${ChatId}`,
        url: gettingChatAdminSetting,
    });

    const chatAdminSettingData = { ...DEFAULT_CHAT_SETTING, ...(chatAdminSettingRequest?.data?.data?.data?.values || {}) }
    console.log("* * * SingleChat chatAdminSettingRequest :", { chatAdminSettingRequest, chatAdminSettingData });

    const chatRequest = useQueryCustom({
        name: `${API_NAME}_users_get_${SelectedChat?._id}`,
        url: getting,
        enabled: !!SelectedChat?._id
    });

    let { refetch } = chatRequest

    console.log("* * * chatRequest :", { chatRequest });
    let currentChat = chatRequest?.data?.data?.data || SelectedChat;

    let users = (currentChat || SelectedChat)?.members?.map((x) => x._id)

    let isLoading = !SelectedChat?.members ? chatRequest?.isLoading : false
    console.log("* * * currentChat :", { currentChat });

    const gettingUsersGallery = async () => await axiosClient().get(api.gallery.list + `/by-users?users[]=${users[0]}&users[]=${users[1]}`);
    console.log({ indexOfChat, SelectedChat });


    // const gettingMessage = async (params) => {
    //     console.log("checking * *", { params });
    //     // if (pagination?.page == params?.pageParam) return false;
    //     const req = await axiosClient().get(api[API_NAME].base + "/messages", {
    //         params: {
    //             limit: pagination.limit,
    //             page: params?.page || 1,
    //             sortBy: pagination.sortBy,
    //             chat: ChatId,
    //             user: user?._id
    //         },
    //     });
    //     if (req?.data?.data?.results) req.data.data.results = req?.data?.data?.results?.reverse()
    //     return req?.data?.data

    // }

    const onSuccessMutating = ({ data }) => {
        toastify.success({ title: "success" });
        // navigation(newRoute.base + newRoute.edit + `/${data?.data?._id || data?.data?._id}`);
    };
    const editUserStatus = useMutationCustom({
        url: updateUserStatus,
        invalidQuery: `${API_NAME}_users_get_${SelectedChat?._id}`,
        onSuccess: onSuccessMutating,
    });

    const galleryRequest = useQueryCustom({
        name: `${API_NAME}_user_gallery_get_${currentChat?._id}`,
        url: gettingUsersGallery,
        enabled: !!users
    });

    let currentGallery = galleryRequest?.data?.data?.data?.results;

    const galleryObject = useMemo(() => {
        if (!currentGallery) return {};
        return (currentGallery)?.reduce?.((acc, curr) => {
            console.log({ curr, acc });
            acc[curr.user] = acc[curr.user] || []
            acc[curr.user].push(curr)
            return acc
        }, {})
    }, [currentGallery])

    // console.log({ galleryObject, currentGallery });

    const userObject = useMemo(() => {
        if (!currentChat) return {};
        return (currentChat?.members)?.reduce((acc, curr) => {
            console.log({ curr, acc });
            acc[curr._id] = curr
            return acc
        }, {})
    }, [currentChat])

    console.log({ userObject });
    // console.log("* * * users :", users);
    // console.log("* * * users?.join(", ") :", users?.join(","));
    // console.log("* * * users?.join(", ") :", (users?.join(",")).split(","));

    const handleChangeStatus = (data) => {
        console.log("* * * handleChangeStatus :", { data });
        if (FakeUser?._id) editUserStatus.mutate({ onlineStatus: data?.value })
    }
    const handleShowUserAvatarPreview = (data) => {
        setPreviewUserAvatar({
            show: true,
            data
        })
    }

    const handleHideUserAvatarPreview = (user) => {
        setPreviewUserAvatar({
            show: false,
        })
    }

    const handleCheckKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
        }
        if (e.key === 'Enter' && e.shiftKey) {
            setMessageInputRow(MessageInputRow + 1);
        }
    }



    const handleStartConversation = () => {
        panelSocket?.emit("start a conversation", {
            "chatId": ChatId
        }, (val) => {
            console.log({ val }, "Chat Start!")
        })
    }

    const handleSendMessage = ({ file } = {}) => {
        console.log({ ChatMessageText, indexOfChat })
        const value = messageInputRef.current.value
        console.log({ file })

        if (!file) if ((SendTextLoading || value.trim() === "")) return setSendTextLoading(false);;
        setSendTextLoading(true);
        console.log({ value })
        let validLanguage = chatAdminSettingData?.fromLang !== chatAdminSettingData?.toLang
        panelSocket?.emit("chat message", {
            "chatId": ChatId,
            "message": {
                "type": file ? "image" : "text",
                ...(value && { "caption": value }),
                ...(file && { image: file })
                //"replyTo": "message-id" //optional
            },
            user: FakeUser._id,
            ...(validLanguage && { languages: { from: chatAdminSettingData?.fromLang || "tr", to: chatAdminSettingData?.toLang } })
        }, (data) => {
            // toastify.success("Message sent!")
            console.log({ data })

            if (data?.status === "error") {
                setSendTextLoading(false);
                toast.error(data?.message || "Error")

            } else {
                let helpArrChat = [...ChatMessages];
                let helpArr = [...FlatChatMessages];

                let helpChatList = [...chatListItems];
                if (helpChatList[indexOfChat]) {
                    // helpArr.push(data?.data)
                    // setChatMessages(helpArr);
                    helpArr.unshift(data?.data)
                    setFlatChatMessages(helpArr);
                    helpArrChat[0].unshift(data?.data)
                    setChatMessages(helpArrChat)
                }
                setSendTextLoading(false);
                // setChatListItems(helpChatList);
                // handleSortChatList();
                messageInputRef.current.value = ""
            }
        })
    }

    const handleDetectUsers = () => {
        console.log("*** SelectedChat:", currentChat);
        for (let i = 0; i < currentChat?.members?.length; i++) {
            const element = currentChat?.members[i];
            if (element.status == "delete") {
                setIsDeleteAccount(element)
            }
            if (element?.type === "system") {
                setFakeUser(element)
            } else {
                setRealUser(element);
                realUserVar = element
            }
        }
    }
    const eventType = {
        onlineStatus: "onlineStatus",
        chatList: "chatList",
        newMessage: "newMessage",
        newChat: "newChat"
    }

    console.log({ ChatMessages, pagination }, "checking * * *");

    const handleSuccessGetData = async ({ data }) => {

        console.log("* * * handleSuccessGetData chat: ", { data }, data?.data?.page);
        // alert()
        // if (data?.data?.page != pagination.page) return;
        // let params = [...ChatsList];
        // params = [...params, ...(data?.data?.results?.reverse?.() || [])];
        setPagination((p) => ({ ...p, totalPages: data?.data?.totalPages, totalResults: data?.data?.totalPages, page: data?.data?.page }));
        console.log("* * * handleSuccessGetData ");

        // setChatsList(params?.results);
        if (data?.data?.results?.length) setChatMessages(p => {

            let newData = [...(p || [])];

            console.log("* * * handleSuccessGetData :", { newData });

            newData[data.data.page - 1] = data.data.results//?.reverse();

            console.log("* * * handleSuccessGetData :", { newData });

            return newData
        });

        setFlatChatMessages(p => {
            let chat = [...(ChatMessages || [])];
            chat[data.data.page - 1] = data.data.results//?.reverse();
            // return chat?.reverse()?.flatMap?.(item => {
            //     console.log({ item }, "checking * * *");
            //     return item
            // })
            let chatDate = ''

            return chat?.flatMap?.(item => {
                console.log({ item }, "checking * * *");
                return item
            })

            // chat?.forEach((x, i) => {
            //     if (x?.createdAt) {
            //         if (chatDate == '') {
            //             console.log("ii", i, { chatDate });
            //             chatDate = x?.createdAt.split('T')[0] || new Date();
            //             return chat.splice(i, 0, { date: x?.createdAt, type: "date" })
            //             // format(new Date(x?.createdAt), 'yyyy MMMM dd')

            //         } else if (chatDate != x?.createdAt.split('T')[0]) {
            //             console.log("ii", i);

            //             chatDate = x?.createdAt.split('T')[0];
            //             return chat.splice(i, 0, { date: x?.createdAt, type: "date" })
            //         }
            //     } else {
            //         chatDate = moment(new Date()).format('yyyy/MM/d') // format(new Date(), 'yyyy/MM/dd');
            //         return chat.splice(i, 0, { date: new Date(), type: "date" })
            //     }
            // })

            // return chat
        })

        // await delay(3000);
        // setShowObserve(true)
        // if (data?.data?.page == 1) chatMainBody.current.scroll({ top: chatMainBody.current.scrollHeight });
        // let onlineUsersState = []
        //     // [...(OnlineUsers || [])]
        //     ,
        //     chatListState = []
        //     // [                ...(params?.results || [])]
        //     ,
        //     newMessagesState = []
        //     ,
        //     newChatState = {}
        // // { ...(NewMessages || {})         }

        // const callback = ({ data: newData, type }) => {

        //     console.log("* * * callback", { newData, type });

        //     switch (type) {
        //         // case eventType.onlineStatus:
        //         //     onlineUsersState = newData;
        //         //     setOnlineUsers(p => ([...p, ...newData]))
        //         //     break;
        //         // case eventType.chatList:
        //         //     chatListState = newData;
        //         //     setChatsList(p => ([...p, ...newData]))
        //         //     break;
        //         case eventType.newMessage:
        //             newMessagesState = newData;
        //             setChatMessages(p => ([...p, ...newData]))
        //             break;
        //         // case eventType.newChat:
        //         //     newChatState = newData;
        //         //     setChatMessages(p => ({ ...p, ...newData }))
        //         //     break;
        //         default:
        //             break;
        //     }
        // }

        // handleListenSocket({
        //     state: {
        //         onlineUsersState, chatListState, newMessagesState, newChatState
        //     },
        //     callback
        // })
    }



    const handleGetMessagesOfChat = ({ page } = { page: 1 }) => {
        setChatLoading(true)
        // setShowObserve(false)

        const user = SelectedChat?.members?.filter((x) => {
            return x.type == "system"
        })[0]
        console.log({ SelectedChat });
        let { totalPages, totalResults, ...parameter } = pagination
        axiosClient().get(api[API_NAME].base + "/messages", {
            params: {
                // limit: 100,
                // page: 1,
                // chat: ChatId,
                // sortBy: 'createdAt:asc',
                ...parameter,
                page,
                user: user?._id
            }
        }).then(({ data }) => {
            console.log("**** data", { data });
            // let prevChatMessage = [...data?.data?.results];
            // prevChatMessage = [...data?.data?.results , ...ChatMessages]
            // prevChatMessage = [...data?.data?.results]
            // prevMainChatMessage = [...data?.data?.results]
            handleSuccessGetData({ data: data })
            // handleListenSocket()


            setChatLoading(false)
        }).catch((err) => {
            console.log({ err })
            setChatLoading(false)

        })
    }

    const chatMessageRequest = {}
    // useQueryCustom({
    //     name: `${API_NAME}_get`,
    // params: {
    //     limit: pagination.limit,
    //     page: pagination.page,
    //     sortBy: pagination.sortBy,
    //     chat: ChatId,
    // },
    //     url: gettingMessage,
    //     onSuccess: handleSuccessGetData,
    //     // enabled: !searchList
    // });

    // const { data, ...chatMessageRequest } = useInfiniteQuery([
    //     `${API_NAME}_get_${ChatId}`,
    //     // {
    //     //     limit: pagination.limit,
    //     //     page: pagination.page,
    //     //     sortBy: pagination.sortBy,
    //     //     chat: ChatId,
    //     // }
    // ], gettingMessage, {
    //     getNextPageParam: (lastPage, pages) => {
    //         console.log({ lastPage, pages }, "checking * * * ");
    //         const nextPage = lastPage ? lastPage.page + 1 : 1;
    //         // setPagination((p) => ({ ...p, totalPages: lastPage?.totalPages, totalResults: lastPage?.totalPages, page: lastPage?.page }));
    //         // return lastPage?.totalPages === pages.length ? undefined : pages.length + 1;
    //         return nextPage <= lastPage.totalPages ? nextPage : undefined;
    //     },
    //     // getPreviousPageParam:(lastPage, pages)=>{
    //     //     return nextPage <= lastPage.totalPages ? nextPage : 1;
    //     // }

    // });

    const flattenedData = FlatChatMessages
    // useMemo(
    //     () => {
    //         console.log({ ChatMessages }, "checking * * *");

    //         // []?.f
    //         console.log(ChatMessages?.flatMap?.(item => {
    //             console.log({ item }, "checking * * *");
    //             return item
    //         }), "flattenedData checking * * *");
    //         return (
    //             // data ? data?.pages?.reverse()?.flatMap(item => item.results) : []
    //             ChatMessages?.reverse?.()?.flatMap?.(item => {
    //                 console.log({ item }, "checking * * *");
    //                 return item
    //             })
    //         )
    //     },
    //     [ChatMessages]
    // );
    let data = []
    console.log({ flattenedData, data, }, "checking * * * ");
    console.log({ chatMessageRequest }, "checking * * * ");
    // ?.reverse()
    const newMyChatMessage = (params) => {
        console.log("* * * * newMyChatMessage (chat) : ", { params })
        const data = params?.result
        console.log("* * * * newMyChatMessage  data?.chat == ChatId: ", data?.chat == ChatId)

        if (data?.chat == ChatId) {
            setChatMessages(state => {
                let newState = [...state];
                newState[0].unshift(data)
                console.log(" * * * newMyChatMessage setChatMessages", { newState });
                return newState
            })

            setFlatChatMessages(state => {
                console.log("* * * new chat state: ", { state })
                let newState = [...state];
                // newState.push(data)
                newState.unshift(data)
                console.log({ socketData: data, myData: RealUser, myDataVar: realUserVar })
                console.log({ newState });
                return newState
            });

            // setChatMessages(prev => {
            //     console.log("* * * * newMyChatMessage setChatMessages  data?.chat == ChatId: ", { prev })
            //     return ([...prev, data])
            // });
        }

    }



    // const handleListenSocket = (params) => {
    //     // panelSocket.connect();
    //     console.log("* * * panelSocket:", { panelSocket })
    //     // --------------------------------- off before event


    //     // panelSocket.off('new chat', newChat);
    //     // --------------------------------- on new event

    //     // panelSocket.on('new chat', (e) => newChat(e, params));
    // }



    // --------------------------------------------------------- 

    const onlineStatus = (data, { state, callback } = {}) => {
        console.log("* * * onlineStatus : ", { data })
        let helpArr = [...state.onlineUsersState];
        if (data.status === "online") {
            helpArr.push(data?.user);
        } else {
            helpArr.filter(item => item !== data?.user)
        }

        // setOnlineUsers(helpArr)
        // callback({ type: eventType.onlineStatus, data: helpArr })
        console.log({ data })
    }
    // --------------------------------------------------------- 

    const handleNextPage = () => {
        const nextPage = pagination.page + 1;
        console.log("checking * * * * * next page hasNextPage", nextPage);
        // if (chatMessageRequest?.hasNextPage) chatMessageRequest.fetchNextPage()
        if (nextPage <= pagination.totalPages) handleGetMessagesOfChat({ page: nextPage })
        // setPagination((p) => ({ ...p, page: nextPage }));
    }
    // const chatMessage = (data, { state, callback } = {}) => {
    //     console.log("* * * * chat message : ", { data },)

    //     let helpObj = state.newMessagesState;
    //     helpObj[data?.chat] = {
    //         ...(helpObj[data?.chat] && helpObj[data?.chat]),
    //         count: +(helpObj[data?.chat]?.count || 0) + 1,
    //         lastMessage: data?.message?.type === "text" ? data?.message?.caption : "File"
    //     }
    //     callback({ type: eventType.newMessage, data: helpObj })
    // }
    // --------------------------------------------------------- 
    const chatMessage = (params) => {
        console.log("* * * new chat (chat): ", { params })
        const data = params?.result
        if (data?.chat === ChatId) {

            // setChatMessages
            setFlatChatMessages(state => {
                console.log("* * * new chat state: ", { state })
                let newState = [...state];
                // newState.push(data)
                newState.unshift(data)
                console.log({ socketData: data, myData: RealUser, myDataVar: realUserVar })
                console.log({ newState });
                return newState
            });

            setChatMessages(state => {
                let newState = [...state];
                newState[0].unshift(data)
                return newState
            })
        }
    }
    // --------------------------------------------------------- 
    const handleSetImage = async ({ file, imagePreviewUrl }) => {

        try {
            setSendTextLoading(true);
            console.log({ file });
            const formData = new FormData();
            formData.append("file", file);
            formData.append("type", "chat");
            formData.append("userId", FakeUser?._id);
            const uploadImage = await axiosClient().post(api.file.base, formData)
            console.log({ uploadImage });
            handleSendMessage({ file: uploadImage?.data?.data })
        } catch (error) {
            toast.error("please try again!");
            console.log({ error });
            setSendTextLoading(false);
        }


        // file
    }

    // const handleListenSocket = () => {
    //     console.log(panelSocket)
    //     panelSocket.connect();
    //     panelSocket.on('onlineStatus', (data) => {
    //         console.log("* * * SingleChat  onlineStatus:", { data })
    //     });
    //     panelSocket.on('chat message', (data) => {
    //         console.log("* * * SingleChat chat message :", { data })
    //         console.log("* * * SingleChat chat message correct chat:", data?.chat === ChatId)

    //         if (data?.chat === ChatId) {
    //             let helpArr = [...prevMainChatMessage];
    //             let helpObj = {
    //                 header: { type: data?.message?.type },
    //                 content: { caption: data?.message?.caption },
    //                 createdAt: formatISO(new Date()),
    //             }

    //             if (data?.user === RealUser?._id || data?.user === realUserVar?._id) {
    //                 helpObj["user"] = { ...realUserVar }
    //             }

    //             helpArr.push(helpObj)

    //             console.log({ socketData: data, myData: RealUser, myDataVar: realUserVar })
    //             console.log({ helpArr });
    //             prevMainChatMessage = helpArr;
    //             setChatMessages(helpArr);
    //         }
    //     });
    // }

    let firstNote = useMemo(() => {
        if (!currentChat) return {
            user: SelectedChat?.members?.[0]
        }
        let parameter = currentChat?.notes?.find((x) => x.user == currentChat?.members?.[0]?._id) || {}
        if (parameter) parameter.user = currentChat?.members?.[0]
        return parameter
    }, [currentChat])

    let secondNote = useMemo(() => {
        if (!currentChat) return {
            user: SelectedChat?.members?.[1]
        }
        let parameter = currentChat?.notes?.find((x) => x.user == currentChat?.members?.[1]?._id) || {}
        if (parameter) parameter.user = currentChat?.members?.[1]
        return parameter

    }, [currentChat])

    console.log({ firstNote, secondNote });

    // useEffect(()=>{
    //     handleGetMessagesOfChat();
    // },[])
    useEffect(() => {
        if (currentChat?.members) handleDetectUsers();
    }, [currentChat])

    useEffect(() => {
        console.log({ topChatMainBody: topChatMainBody.current });
        if (pagination?.page == 1) chatMainBody.current.scroll({ top: chatMainBody.current.scrollHeight });
        // else             topChatMainBody.current.scroll({ top: topChatMainBody.current. })

    }, [pagination])


    useEffect(() => {

        let first = !flattenedData?.length
        console.log("checking * * *", { first });
        handleGetMessagesOfChat({ page: 1 })
        // handleListenSocket();

        // if (chatMainBody) {
        //     chatMainBody.current.addEventListener('DOMNodeInserted', event => {
        //         const { currentTarget: target } = event;
        //         if (first) {
        //             target.scroll({ top: target.scrollHeight });
        //             first = false
        //         }

        //         console.log("checking * * *", { first, flattenedData });

        //     });
        // }
        if (!socketState.data) return;
        handleStartConversation();

        panelSocket.on('onlineStatus', onlineStatus);
        panelSocket.on('chat message', chatMessage);
        panelSocket.on('/agent/my-chat-message', newMyChatMessage);

        return () => {
            panelSocket.off('onlineStatus', onlineStatus);
            panelSocket.off('chat message', chatMessage);
            panelSocket.off('/agent/my-chat-message', newMyChatMessage);
        }
    }, [socketState])




    function swapElementsByType(arr, targetType) {
        console.log("* * *  swapElementsByType:", { arr, targetType });
        if (!arr?.length) return;
        const systemIndex = arr.findIndex(item => item.type === targetType);
        console.log("* * *  swapElementsByType:", { systemIndex });

        if (systemIndex == 0 && systemIndex !== -1 && arr[systemIndex].type === targetType) {
            const otherIndex = arr.findIndex((item, index) => index !== systemIndex && item.type !== targetType);
            if (otherIndex !== -1) {
                [arr[systemIndex], arr[otherIndex]] = [arr[otherIndex], arr[systemIndex]];
            }
        }
        return arr;
    }

    let members = useMemo(() => {
        return swapElementsByType(currentChat?.members, "system");
    }, [currentChat])

    console.log({ members, ChatMessages });

    return (
        <>

            <Modal show={PreviewUserAvatar.show} onHide={handleHideUserAvatarPreview}>
                <Modal.Body className="text-center">
                    <img src={PreviewUserAvatar?.data} alt="" className="mw-100" />
                </Modal.Body>
            </Modal>

            <div className="d-flex singleChatWrapper h-100">

                <div className={`singleChatMainBox ${OpenChatInfo ? " openSide" : ""} h-100`}>

                    <div className="row m-0  h-100 ">

                        <div className="col-8 p-0  h-100 d-flex flex-column">

                            <div className="d-flex align-items-center justify-content-between gap-2 singleChatMainHeaderBox">
                                <div className="chatHeader">
                                    <Row>
                                        {(
                                            // chatRequest?.data?.data?.data?.results ||
                                            // (currentChat)?.members
                                            members
                                        )?.map((item, index) => {
                                            let galleries = galleryObject[item?._id]
                                            return <div className="col-6 " key={"Selected-Chat-" + index}>
                                                {console.log("item :", item)}
                                                <div className="chatHeaderColumn">
                                                    <div className="singleChatHeaderAvatar cursor-pointer" onClick={() => handleShowUserAvatarPreview(item.avatar?.url)}>
                                                        {
                                                            item.avatar?.url ?
                                                                <img src={item.avatar?.url || PLACEHOLDER_IMAGE} alt="" />
                                                                :
                                                                <div className="avatar-name-holder avatar-name-header-holder">
                                                                    <span>{item?.name?.slice(0, 2)}</span>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="singleChatHeaderBody">

                                                        <div
                                                            className="text-dark text-decoration-none">
                                                            <div className="mb-1 d-flex">
                                                                <b title={item?.name} className={styles.chatUserName}>{item?.name || "-"}</b>
                                                                <span className="text-info"> ({item?.type == 'system' ? "system" : item?.type})</span>
                                                                <i className="mdi mdi-wallet menu-icon ml-3 mr-1 text-warning"></i>
                                                                <b className="text-warning">{+item?.wallet?.coins || 0}</b>
                                                                <b className="ml-3">{item?.status == 'delete' ? <span className="text-danger">Deleted</span> : <span className="text-success">{item?.status}</span>}</b>
                                                            </div>
                                                        </div>

                                                        <div className="text-dark text-decoration-none">
                                                            <div className="pt-1"><i className="mdi mdi-account menu-icon mr-1 text-info"></i><span>{item?.ref || "-"}</span></div>
                                                        </div>

                                                        <div className="text-dark text-decoration-none">
                                                            <div className="mt-1 d-flex align-items-center"><i className="mdi mdi-clock menu-icon mr-1 text-info"></i>
                                                                <span>
                                                                    {/* 2023-09-12 17:44 */}
                                                                    {
                                                                        item?.type == 'system' ?
                                                                            <DropDownBootstrap
                                                                                click={handleChangeStatus}
                                                                                data={ONLINE_STATUSES}
                                                                                title={ONLINE_STATUSES_OBJECT[item.onlineStatus]?.label}
                                                                                value={ONLINE_STATUSES_OBJECT[item.onlineStatus]?.value}
                                                                                color={ONLINE_STATUSES_OBJECT[item.onlineStatus]?.color}
                                                                                className={styles.onlineButton}
                                                                            />
                                                                            //  "online"
                                                                            :
                                                                            IsDeleteAccount ? "offline" :
                                                                                item?.lastTimestamp?.offline ?
                                                                                    formatTimeAgo(item?.lastTimestamp?.offline)
                                                                                    : "online"
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="text-dark text-decoration-none">
                                                            <div className="mt-2"><span>{item?.bio}</span></div>
                                                        </div>

                                                        <div className="text-dark text-decoration-none">
                                                            <div className="mt-3 chat-header-gallery">
                                                                {
                                                                    galleries?.map((y) => y?.files?.map((z, i) => {
                                                                        return (z?.file ?
                                                                            <div className="chat-header-gallery-item cursor-pointer mb-2" key={z?.file?.url} onClick={() => handleShowUserAvatarPreview(z?.file?.url)}>
                                                                                <img src={z?.file?.url || PLACEHOLDER_IMAGE} alt="" />
                                                                            </div> : ""
                                                                        )
                                                                    }))
                                                                }
                                                                {
                                                                    item?.galleries?.length && !galleries?.length ? <>
                                                                        <div className="chat-header-gallery-item">
                                                                            <img src={PLACEHOLDER_IMAGE} alt="" />
                                                                        </div>
                                                                        <div className="chat-header-gallery-item">
                                                                            <img src={PLACEHOLDER_IMAGE} alt="" />
                                                                        </div>
                                                                        <div className="chat-header-gallery-item">
                                                                            <img src={PLACEHOLDER_IMAGE} alt="" />
                                                                        </div>
                                                                        <div className="chat-header-gallery-item">
                                                                            <img src={PLACEHOLDER_IMAGE} alt="" />
                                                                        </div></> : ""
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        })}
                                        {/* <div className="col-6">
                                    
                                    
                                    </div> 
                                    <div className="col-6">col 6</div>  */}
                                    </Row>
                                </div>
                            </div>



                            <div className="singleChatMainBody" ref={chatMainBody}  >



                                {/* {flattenedData?.length ? (
                                            <FindMe callback={handleNextPage} margin={"50px"} />
                                        ) : null} */}



                                {chatMessageRequest?.isLoading || isLoading ?
                                    ""
                                    :
                                    <>

                                        {flattenedData?.map(chat => {
                                            let currentUser = userObject?.[chat?.user?._id || chat?.user]
                                            console.log({ chat, currentUser });
                                            // console.log({ format: format(new Date(chat?.createdAt), "HH:MM") }, chat?.createdAt, moment(chat?.createdAt).format('h:mm a'));
                                            // console.log("chat?.createdAt", chat?.createdAt);
                                            return <Fragment key={`chat-message-${ChatId}-${chat?._id}`}>
                                                {/* {!ChatLoading ? <div ></div> : <p className="py-2"></p>} */}
                                                {/* {(() => {
                                                    if (chat?.createdAt) {
                                                        if (chatDateRecent == '') {
                                                            chatDateRecent = chat?.createdAt.split('T')[0] || new Date();
                                                            return <div className='SCC-mainDate'>{moment(chat?.createdAt).format('yyyy MMMM d')
                                                                // format(new Date(chat?.createdAt), 'yyyy MMMM dd')
                                                            }</div>
                                                        } else if (chatDateRecent != chat?.createdAt.split('T')[0]) {
                                                            chatDateRecent = chat?.createdAt.split('T')[0];
                                                            return <div className='SCC-mainDate'>{
                                                                // format(new Date(chat?.createdAt), 'yyyy MMMM dd')
                                                                moment(chat?.createdAt).format('yyyy MMMM d')
                                                            }</div>
                                                        }
                                                    } else {
                                                        chatDateRecent = moment(new Date()).format('yyyy/MM/d') // format(new Date(), 'yyyy/MM/dd');
                                                        return <div className='SCC-mainDate'>{
                                                            // format(new Date(), 'yyyy MMMM dd')
                                                            moment(new Date()).format('yyyy MMMM d')
                                                        }</div>
                                                    }
                                                })()} */}
                                                {/* { */}
                                                {/* // chat?.type == "date" ? <div className='SCC-mainDate'>
                                                    //     {moment(chat?.date).format('yyyy MMMM d')}</div>
                                                    //     :
                                                    <div className="d-flex align-items-end messageCardBox" key={`chat-message-${ChatId}-${chat?._id}`}>
                                                        {!(currentUser?.type === "system") &&

                                                            <img src={currentUser?.avatar?.url || PLACEHOLDER_IMAGE} alt="" />

                                                        }

                                                        <div className={`messageCard${(currentUser?.type === "system") ? " me" : ""}`}>
                                                            <div className="messageCardName">{chat.me ? "You" : currentUser?.name}</div>
                                                            <div className="messageCardBody">
                                                                {chat?.header?.type === "text" ?
                                                                    <>
                                                                        {chat?.content?.caption}
                                                                    </>
                                                                    :
                                                                    <div className="chat-image">
                                                                        <img className="rounded cursor-pointer" src={chat?.content?.image?.secureUrl} onClick={() => handleShowUserAvatarPreview(chat?.content?.image?.secureUrl)} />
                                                                        <div className="mt-1">
                                                                            {chat?.content?.caption}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="messageCardFooter">
                                                                <div className="messageCardFooterDate">
                                                                   
                                                                    {moment(chat?.createdAt).format('MM.DD , HH:mm')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>} */}

                                                <MessageComponent {...{ currentUser, message: chat, ChatId, handleShowUserAvatarPreview }} />

                                            </Fragment>
                                        }
                                        )}
                                    </>
                                }
                                {ChatLoading || isLoading ? (
                                    <div className={["text-center", styles.loading].join(" ")}>
                                        <Loading />
                                    </div>
                                ) : flattenedData?.length && pagination?.totalPages > pagination.page ? (
                                    <FindMe callback={handleNextPage} margin={"5px"} />
                                ) : null}

                            </div>

                            {IsDeleteAccount ?
                                ""
                                // "disable"
                                :
                                <ChatFooter  {...{ handleCheckKeyPress, messageInputRef, handleSetImage, SendTextLoading, handleSendMessage, languages, chatAdminSettingData }} />

                            }

                        </div>
                        <div className="col-4 p-0  h-100">

                            {/* <Notes {...{ refechChatList, ChatId, SelectedChat:currentChat }} /> */}
                            <Note
                                //  user={SelectedChat:currentChat?.members[0]}
                                data={firstNote}
                                {...{ refechChatList, ChatId, SelectedChat: currentChat, isLoading: chatRequest.isLoading, refetch }} />
                            <Note
                                // user={SelectedChat:currentChat?.members[1]}
                                data={secondNote}

                                {...{ refechChatList, ChatId, SelectedChat: currentChat, isLoading: chatRequest.isLoading, refetch }} />

                        </div>

                    </div>

                </div>

            </div>

        </>
    );
}

export default SingleChat;