import toastify from "lib/toastify";
import { useMutation, useQueryClient } from "react-query";
import { changeError } from "utils/errors";

export function useMutationCustom({ name, url, onSuccess, invalidQuery }) {
  const queryClient = useQueryClient();

  return useMutation(name ? name : "", url, {
    onSuccess,
    onError: (error) => {
      // const changedError = changeError(error);

      if (error?.response?.status == "401") {
        toastify.error("please try again!");
      } else {
        toastify.error({ title: error?.response?.data?.message });

      }


      // Object.entries(changedError).map(([key, value]) => {
      //   return toastify.error({ title: value.message });
      // });
    },
    onSettled: () => {
      if (invalidQuery) queryClient.invalidateQueries(invalidQuery);
    },
  });
}
