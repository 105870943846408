import FieldText from "components/FieldText";
import styles from "./index.module.scss";
import { useQueryCustom } from "utils/useQueryCustom";
import axiosClient from 'lib/axiosConfig';
import api from "operations/network/api";
import EmojiPicker from "emoji-picker-react";
import Loading from "components/Loading";
import SelectFileComponent from "components/SelectFileComponent";
import { useState } from "react";
import ModalLayout from "components/ModalLayout";
import SelectLanguage from "../SelectLanguage";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

const ChatFooter = ({ handleCheckKeyPress, messageInputRef, handleSetImage, SendTextLoading, handleSendMessage, languages, chatAdminSettingData }) => {

    let { id } = useParams();
    const [toggle, setToggle] = useState()
    const [modalToggle, setModalToggle] = useState()
    const [toggleLanguage, setToggleLanguage] = useState()

    const languageSelector = useSelector(state => state.messageLanguage);
    const currentLanguage = languageSelector[id];

    const gettingSetting = async () => await axiosClient().get(api.settings.get + '/get-one', { params: { key: "moreUsedWordsRecently" } });
    const settingRequest = useQueryCustom({
        name: `get_word_setting`,
        url: gettingSetting,
    });
    let settingData = settingRequest?.data?.data?.data?.value && JSON.parse(settingRequest?.data?.data?.data?.value);

    const onKeyUp = (e) => {

        handleCheckKeyPress(e)
    }


    const onMouseEnter = (e) => {
        console.log({ e }, "onMouseEnter");
        setToggle(true)
    }
    const onMouseLeave = (e) => {
        console.log({ e }, "onMouseLeave");
        setToggle(false)

    }
    const handleSetText = (text) => {
        messageInputRef.current.value = text
    }

    const handleShowTranslate = () => {
        setModalToggle(true)
    }
    // const handleShowLanguage = () => {
    //     setToggleLanguage(p => !p)
    // }


    const handleAccepted = (text) => {
        handleSetText(text)
        setModalToggle(false)
    }

    return (
        <div className={styles['chat-footer']}>

            {/* <ModalLayout setShow={setModalToggle} show={modalToggle} title={"Translator"} > */}
            {/* <div className={[styles.selectLanguage, toggleLanguage && styles["visible"]].join(" ")}> */}
            {/* <SelectLanguage handleAccepted={handleAccepted} languages={languages} /> */}
            {/* </div> */}
            {/* </ModalLayout> */}


            <div className="d-flex align-items-center gap-2 singleChatMainFooter">

                <div className={styles.microphone}>
                    <i className="mdi mdi-microphone" />
                </div>

                <SelectLanguage handleAccepted={handleAccepted} languages={languages?.from} name="fromLang" value={chatAdminSettingData["fromLang"]} defaultValue={"tr"} />

                <div className={styles.inputBox} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>

                    <div className={[styles["default-words-wrapper"], toggle && styles["visible"]].join(" ")}>
                        <ul className={styles["default-words-box"]}>
                            {
                                settingData?.map((x) => (
                                    <li className={styles["default-word"]} key={x} onClick={() => handleSetText(x)}>
                                        <span>
                                            {x}
                                        </span>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>

                    <FieldText

                        className={["textMessageInput", styles.input].join(" ")}
                        type={"text"}
                        placeholder={"Message"}
                        refrence={messageInputRef}


                        // as={"textarea"}
                        // row={MessageInputRow}
                        style={{
                            minHeight: "45px",
                            // height: MessageInputRow * 10 + "px",
                        }}
                        onKeyUp={onKeyUp}
                        onChangeCapture={(e) => console.log({ e: e.target.value })}
                        onResetCapture={(e) => console.log({ e: e.target.value })}

                    //   onChange={(e) => setChatMessageText(e.target.value)}
                    //  value={ChatMessageText}
                    />
                </div>
                <SelectLanguage handleAccepted={handleAccepted} languages={languages?.to} name="toLang" value={chatAdminSettingData["toLang"]} />

                {/* 
                <div className={styles.translateBadge} onClick={handleShowLanguage}>
                    {currentLanguage?.label || "Language"}
                    <i className="mdi mdi-chevron-down" />
                </div> */}

                <div className="emojiChatFooterMainBox">
                    <div className="emojiChatBox">
                        <EmojiPicker
                            lazyLoadEmojis={true}
                            emojiStyle={"native"}
                            previewConfig={{ showPreview: false }}
                            skinTonesDisabled={true}
                            onEmojiClick={(emojiData) => {
                                console.log({ messageInputRef });
                                let value = messageInputRef.current.value;
                                messageInputRef.current.value = value + emojiData?.emoji
                                //setChatMessageText(ChatMessageText + emojiData?.emoji)
                            }}
                        />
                    </div>
                    <span className="mdi mdi-emoticon-outline emojiChatFooterIcon"></span>
                </div>

                {/* <div className="mx-1"></div> */}
                <SelectFileComponent onChange={handleSetImage} />
                {/* <div className="mx-1"></div> */}

                <div className={`singleChatMainFooterButton ${SendTextLoading ? styles["disabled-button"] : ""}`} onClick={!SendTextLoading && handleSendMessage} disabled={SendTextLoading || true}>
                    {SendTextLoading ? <Loading /> : <i className="mdi mdi-send-outline"></i>}
                </div>
            </div>
        </div>
    )
}
export default ChatFooter